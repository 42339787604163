/**
 * 配置常量
 */
module.exports = {
  jwt: {
    'headerName': 'authorization',
    'headerNamePrefix': 'Bearer ',
    'secret': 'shared-secret'
  }
}
